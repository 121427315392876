// Auto select all content of input and textarea
document.querySelectorAll("input,textarea").forEach(textarea => {
  textarea.addEventListener("mouseover", ev => ev.target.select());
  textarea.addEventListener("mouseout", () => document.getSelection().empty());
});

function arrayBufferToBase64(buffer) {
  const strAscii = String.fromCharCode(...new Uint8Array(buffer));
  return btoa(strAscii);
}

async function base64DigestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  return arrayBufferToBase64(hashBuffer); // convert buffer to base64
}

// Date
const dateCasing = document.getElementById("date-casing");
const dateInput = document.getElementById("date-input");

let date = new Date().toUTCString();
let dateHeader;
function updateDate() {
  date = new Date().toUTCString();
  updateDateHeader();
}
function updateDateHeader() {
  dateHeader = `${dateCasing.value}: ${date}`;
  dateInput.value = dateHeader;
  updateStringToBeSigned();
}
setTimeout(updateDate, 0);

dateCasing.addEventListener("change", updateDateHeader);
dateInput.addEventListener("input", updateStringToBeSigned);

const refreshDateButton = document.getElementById("refresh-date");
refreshDateButton.addEventListener("click", updateDate);

// Line endings
const lineEndingSelect = document.getElementById("line-ending");
const httpReqBody = document.getElementById("http-request-body");
function getHttpReqBody() {
  const lineEnding = lineEndingSelect.value;
  if (lineEnding === 'lf') {
    return httpReqBody.value;
  } else {
    return httpReqBody.value.replace(/\n/g, '\r\n');
  }
}

// Content-Length
const zeroLengthBody = document.getElementById("zero-length-body");
const contentLength = document.getElementById("content-length");

function updateContentLength() {
  if (zeroLengthBody.checked) {
    contentLength.textContent = `Content-Length: 0`;
  } else {
    const body = getHttpReqBody();
    contentLength.textContent = body.length ? `Content-Length: ${body.length}` : "";
  }
}
httpReqBody.addEventListener("input", updateContentLength);
zeroLengthBody.addEventListener("change", updateContentLength);
lineEndingSelect.addEventListener("change", updateContentLength);

// Digest
const httpReqHeaderDigest = document.getElementById("http-request-header-digest");
const digestCasing = document.getElementById("digest-casing");

let digest;
let digestHeader;
async function updateDigest() {
  const body = getHttpReqBody();
  digest = await base64DigestMessage(body);
  digestHeader = `${digestCasing.value}: SHA-256=${digest}`;
  httpReqHeaderDigest.textContent = digestHeader;
  updateStringToBeSigned();
}

httpReqBody.addEventListener("input", updateDigest);
digestCasing.addEventListener("change", updateDigest);
lineEndingSelect.addEventListener("change", updateDigest);

// HMAC headers
const hmacHeadersInput = document.getElementById("hmac-headers");
function updateDigestHeaders() {
  if (httpReqBody.value || zeroLengthBody.checked) {
    hmacHeadersInput.value = `${dateCasing.value} ${digestCasing.value}`;
  } else {
    hmacHeadersInput.value = dateCasing.value;
  }
  updateStringToBeSigned();
}

httpReqBody.addEventListener("input", updateDigestHeaders);
dateCasing.addEventListener("change", updateDigestHeaders);
digestCasing.addEventListener("change", updateDigestHeaders);

zeroLengthBody.addEventListener("change", () => {
  if (zeroLengthBody.checked) {
    httpReqBody.value = "";
    httpReqBody.disabled = true;
    updateDigest();
  } else {
    httpReqBody.disabled = false;
  }
  updateDigestHeaders();
});

// String to be signed
const stringToBeSignedTextarea = document.getElementById("string-to-be-signed");
function updateStringToBeSigned() {
  const headers = [];
  for (let key of hmacHeadersInput.value.split(" ")) {
    let value;
    switch (key) {
      case dateCasing.value:
        headers.push(dateInput.value);
        break;
      case digestCasing.value:
        value = digest;
        headers.push(digestHeader);
        break;
    }
  }
  stringToBeSignedTextarea.value = headers.join('\n');
  updateAuthHeader();
}
dateCasing.addEventListener("change", updateStringToBeSigned);
digestCasing.addEventListener("change", updateStringToBeSigned);

// Authorization header
const hmacSecretInput = document.getElementById("hmac-secret");
const httpReqHeaderAuthorization = document.getElementById("http-request-header-authorization");
const hmacUsernameInput = document.getElementById("hmac-username");
const authorizationCasing = document.getElementById("authorization-casing");

let authorization;

async function updateAuthHeader() {
  const secret = hmacSecretInput.value;
  if (!secret) return;

  const algo = { name: "HMAC", hash: "SHA-256" };
  const key = await crypto.subtle.importKey("raw", new TextEncoder().encode(secret), algo, false, ["sign"]);

  const encoded = new TextEncoder().encode(stringToBeSignedTextarea.value);

  const signature = await crypto.subtle.sign(algo.name, key, encoded);

  const username = hmacUsernameInput.value;
  authorization = `hmac username="${username}", algorithm="hmac-sha256", headers="${hmacHeadersInput.value}", signature="${arrayBufferToBase64(signature)}"`;
  httpReqHeaderAuthorization.value = `${authorizationCasing.value}: ${authorization}`;

  updateCurlCommand();
}

authorizationCasing.addEventListener("change", updateAuthHeader);
dateCasing.addEventListener("change", updateAuthHeader);
digestCasing.addEventListener("change", updateAuthHeader);
stringToBeSignedTextarea.addEventListener("input", updateAuthHeader);
hmacUsernameInput.addEventListener("input", updateAuthHeader);
hmacSecretInput.addEventListener("input", updateAuthHeader);

// Curl
const generatedCurlCommand = document.getElementById("generated-curl-command");
const urlInput = document.getElementById("target-url");

let curlCmd;
function updateCurlCommand() {
  if (!authorization) return;

  const headers = httpReqBody.value || zeroLengthBody.checked ? [
    `${digestHeader}`,
  ] : [];
  headers.push(
    `${dateInput.value}`,
    `${authorizationCasing.value}: ${authorization}`,
  );

  const url = urlInput.value;
  curlCmd = `curl \\
  ${url} \\
  ${headers.map(h => `-H '${h}'`).join(' \\\n  ')}`;

  if (httpReqBody.value || zeroLengthBody.checked) {
    const lineEnding = lineEndingSelect.value;
    const body = getHttpReqBody();
    if (lineEnding === 'lf') {
      curlCmd += ` \\\n  --data '${body}'`;
    } else {
      data = body.replace(/\r/g, '\\r');
      curlCmd += ` \\\n  --data $'${data}'`;
    }
  }

  generatedCurlCommand.value = curlCmd;
}

urlInput.addEventListener("input", updateCurlCommand);
